import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";

import ENV from '../../env';

import classes from './ExamSection.module.scss';

import { AccountContext } from '../../components/auth/account';

import logoAdd from '../../images/add.svg';
import logoDelete from '../../images/delete.svg';
import loginImage from '../../images/login.svg'
import issueImage from '../../images/issue.svg'
import issueImageActive from '../../images/issueActive.svg'
import PopupReminder from './PopupReminder';
import ReportIssuePopup from './ReportIssuePopup';

const ExamSection = (props) => {

  const questionsData = props.data;
  
  const { getSession } = useContext(AccountContext);

  const [language, setLanguage] = useState({ value: 'english', label: 'English' });
  const [questionNumber, setQuestionNumber] = useState(1);
  const [nextBtn, setNextBtn] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);
  const [userSession, setUserSession] = useState("");
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [reportedQuestions, setReportedQuestions] = useState([]);
  const [isReported, setIsReported] = useState(false);
  const [showLogInReminder, setShowLogInReminder] = useState(false);
  const [showPaymentReminder, setShowPaymentReminder] = useState(false);
  const [userData, setUserData] = useState({});
  const [showReportIssuePopup, setShowReportIssuePopup] = useState(false);
  
  const options = [
    { value: 'english', label: 'English' },
    { value: 'russian', label: 'Russian' },
  ];

  useEffect(() => {
    // If user not authorize show Sign In reminder popup
    if (questionNumber === 6 && userSession === ""){
      setShowLogInReminder(true);
    }

    // Payment reminder Popup
    if (questionNumber === 6 && Object.keys(userData).length !== 0){
      // Check if user paid otherwise show payment reminder
      if (userData.payment_transactions.length === 0){
        setShowPaymentReminder(true);
      }
      else {
        // Check if user have active payment transaction
        let activePayment = userData.payment_transactions.filter(transaction => new Date(transaction.access_expiry) > new Date());
        if (activePayment.length === 0){
          setShowPaymentReminder(true);
        }
      }
    }
    
    // Find corect answer
    questionsData[questionNumber-1].answers.forEach(element => {
      if(element.correct){setCorrectAnswer(String(element.answer_id))}
    });

    // Check if question saved or not
    if (savedQuestions.includes(questionsData[questionNumber-1].question_id)){
        setIsSaved(true);
    } else {
      setIsSaved(false);
    }

    // Check if question reported or not
    if (reportedQuestions.includes(questionsData[questionNumber-1].question_id)){
      setIsReported(true);
    } else {
      setIsReported(false);
    }
  // eslint-disable-next-line
  }, [questionsData, questionNumber]);

  useEffect(() => {
    getSession().then(session => {
      setUserSession(session);
      fetchUsersData(session);
      fetchAllSavedQuestions(session);
      fetchAllReportedQuestions(session);
    })
    .catch(err => {
      console.log(err);
    });
    // eslint-disable-next-line
  }, [getSession]);

  // Get all user saved questions from DB
  const fetchAllSavedQuestions = (session) => {
    axios({
        method: 'get',
        url: ENV.BASE_URL + "/exam/questions/saved",
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`
        }
      })
      .then(response => {
        setSavedQuestions(response.data.data.map(evement => evement.question_id));
        
        // Set saved flag status
        setTimeout(() => {
          let savedChecer = response.data.data.map(evement => evement.question_id)
          if (savedChecer.includes(questionsData[questionNumber-1].question_id)){
            setIsSaved(true);
          } else {
            setIsSaved(false);
          }
        }, 100);
      })
      .catch(function (error) {
        if(!error.response.status === 404){
          console.log("[ERROR: Fetch all saved questions]", error);
        }
      });
  }

  // Get all user reported as issue questions from DB
  const fetchAllReportedQuestions = (session) => {
    axios({
        method: 'get',
        url: ENV.BASE_URL + "/exam/questions/issue",
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`
        }
      })
      .then(response => {
        setReportedQuestions(response.data.data.map(evement => evement.question_id));
        
        // Set saved flag status
        setTimeout(() => {
          let reported = response.data.data.map(evement => evement.question_id)
          if (reported.includes(questionsData[questionNumber-1].question_id)){
            setIsReported(true);
          } else {
            setIsReported(false);
          }
        }, 100);
      })
      .catch(function (error) {
        console.log("[ERROR: Fetch all reported questions]", error);
      });
  }

  // Get user data from DB
  // TODO fetch user transactions instead of all user data
  const fetchUsersData = (session) => {
    if (session === "") return false;
    axios({
        method: 'get',
        url: ENV.BASE_URL + "/users",
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`
        }
      })
      .then(response => {
        setUserData(response.data);
      })
      .catch(function (error) {
        console.log("[ERROR: Fetch user data]", error);
      });
  }

  const onAnswerSelect = (event) => {
    event.preventDefault();

    setSelectedAnswer(String(event.currentTarget.id));
    
    document.getElementById(questionsData[questionNumber-1].answers[0].answer_id).removeAttribute("style");
    document.getElementById(questionsData[questionNumber-1].answers[1].answer_id).removeAttribute("style");
    document.getElementById(questionsData[questionNumber-1].answers[2].answer_id).removeAttribute("style");
    document.getElementById(questionsData[questionNumber-1].answers[3].answer_id).removeAttribute("style");

    event.currentTarget.style.backgroundColor = "#f4b536";
  }

  const onSubmitAnswer = () => {
    setIsSubmited(true);
  
    if (selectedAnswer === correctAnswer) {
      const selectedElement = document.getElementById(selectedAnswer);
      if (selectedElement) {
        selectedElement.style.backgroundColor = "#5fd54d";
      }
  
      setTimeout(() => {
        // Reset all questions
        setSelectedAnswer("");
        setNextBtn(false);
        setIsSubmited(false);
        props.correctNumber();
  
        questionsData[questionNumber - 1].answers.forEach(answer => {
          const answerElement = document.getElementById(answer.answer_id);
          if (answerElement) {
            answerElement.removeAttribute("style");
          }
        });
  
        // Check if question last
        if (questionNumber <= questionsData.length - 1) {
          setQuestionNumber(questionNumber + 1);
        } else {
          props.onComplete();
        }
      }, 500);
    } else {
      // If answer incorrect show correct one and show btn Next question
      const selectedElement = document.getElementById(selectedAnswer);
      const correctElement = document.getElementById(correctAnswer);
      if (selectedElement) {
        selectedElement.style.backgroundColor = "#fc4b4b";
      }
      if (correctElement) {
        correctElement.style.backgroundColor = "#5fd54d";
      }
      setSelectedAnswer("");
      setNextBtn(true);
    }
  }

  const onNextQuestion = () => {
    setTimeout(() => {
      // Reset all questions
      setSelectedAnswer("");
      setNextBtn(false);
      setIsSubmited(false);

      document.getElementById(questionsData[questionNumber-1].answers[0].answer_id).removeAttribute("style");
      document.getElementById(questionsData[questionNumber-1].answers[1].answer_id).removeAttribute("style");
      document.getElementById(questionsData[questionNumber-1].answers[2].answer_id).removeAttribute("style");
      document.getElementById(questionsData[questionNumber-1].answers[3].answer_id).removeAttribute("style");

      // Check if question last
      if(questionNumber <= questionsData.length - 1){
        setQuestionNumber(questionNumber + 1);
      } else {
        props.onComplete();
        return false;
      }
    }, 100);
  }

  const colourStyles = {
    control: (styles) => ({ 
      ...styles,
      // backgroundColor: "red" ,
      // color: "#FFF",
      fontSize: 18,
      border: 1,
      boxShadow: 'none'
    }),
    menuList: styles => ({
        ...styles,
        // background: 'papayawhip',
        // width: 155
    }),
    option: (styles, {isFocused, isSelected}) => ({
        ...styles,
        background: isFocused
            ? '#f6f6f6'
            : isSelected
                ? '#f6f6f6'
                : undefined,
        color: '#1d1d1d',
        zIndex: 1
    }),
    menu: base => ({
        ...base,
        zIndex: 1,
        // color: 'white'
    }),
    singleValue: provided => ({...provided, color: '#1d1d1d'}),
    
    dropdownIndicator: base => ({
      ...base,
      color: "#1d1d1d"
      
    })
  }

  // Save/remove question to/from DB
  const onSaveQuestion = () => {
    // Append/remove question id to/from saved list
    if (savedQuestions.includes(questionsData[questionNumber-1].question_id)){
      setSavedQuestions(current => current.filter(
        element => element !== questionsData[questionNumber-1].question_id
        ));
        setIsSaved(false);
        
        // Show notification that question removed
        toast.error("Question removed from saved list", {
          position: "top-center",
          autoClose: 1500,
          theme: "colored",
        });
    } else {
      setSavedQuestions(current => [...current, questionsData[questionNumber-1].question_id]);
      setIsSaved(true);

      // Show notification that question saved
      toast.success("Question saved", {
        position: "top-center",
        autoClose: 1500,
        theme: "colored",
      });
    }

    axios({
        method: 'post',
        url: ENV.BASE_URL + "/exam/questions/save",
        headers: {
          Authorization: `Bearer ${userSession.idToken.jwtToken}`
        },
        data: {
          "question_id": questionsData[questionNumber-1].question_id,
        }
      })
      .then(response => {
        console.log("On Save response", response);
      })
      .catch(function (error) {
        console.log("[ERROR: ], Save/remove question", error);
      });
  }

  // Report question with issue to DB
  const onIssueQuestion = (selectedList, otherIssue) => {
    // Append question id to reported list
    setReportedQuestions(current => [...current, questionsData[questionNumber-1].question_id]);
    setIsReported(true);

    axios({
        method: 'post',
        url: ENV.BASE_URL + "/exam/questions/issue",
        headers: {
          Authorization: `Bearer ${userSession.idToken.jwtToken}`
        },
        data: {
          "question_id": questionsData[questionNumber-1].question_id,
          "issue": JSON.stringify({
            "selectedList": selectedList,
            "otherIssue": otherIssue ? otherIssue : null
          })
        }
      })
      .then(response => {
        // Show some sucees msg like notification
        toast.info("Thank you for your feedback!", {
          position: "top-center",
          autoClose: 1000,
          theme: "colored",
        });
      })
      .catch(function (error) {
        console.log("[ERROR: ], Report question with issue", error);
      });
  }

  return (
    <div className={classes.section}>
      {/* PopUp window on the begining of Exam */}
      {showLogInReminder && <PopupReminder 
        handleClose={() => {setShowLogInReminder(false)}}
        title="Enhance Your Experience!"
        navigateTo="/login"
        image={loginImage}
        navigateToBtnText="Sign in"
        content="Sign in to explore the TLC exam and enjoy additional features designed to boost your learning journey." />}

        <div className={classes.section__header}>
          <div className={classes.section__header__progress}>
            <ProgressBar 
              className={classes.section__header__progress__wrapper}
              completed={questionNumber}
              customLabel={questionNumber + "/80"}
              labelAlignment={questionNumber <= 10 ? "left" : "center"}
              labelColor="#1d1d1d"
              baseBgColor='#ffffff'
              bgColor='#f4b536'
              margin="10px"
              maxCompleted={80}
            />
          </div>

          <div className={classes.section__header__block}>
            <Select
              className={classes.section__header__block__select}
              // isMulti="true" 
              styles={colourStyles}
              value={language}
              onChange={setLanguage}
              isSearchable={false}
              inputProps={{ readOnly: true }}
              options={options}
            />

            {/* Save/unsave question btn */}
            {userSession !== "" &&
              <div className={classes.section__header__block__flag}>
                {!isSaved && <img data-tooltip-id="save-question-tooltip" src={logoAdd} alt="Flag" onClick={onSaveQuestion} />}
                {isSaved && <img data-tooltip-id="delete-saved-question-tooltip" src={logoDelete} alt="Flag" onClick={onSaveQuestion} />}
              </div>
            }

            {/* Report as issue btn */}
            {userSession !== "" &&
              <div className={classes.section__header__block__flag}>
                {!isReported && <img data-tooltip-id = "report-question-tooltip" src={issueImage} alt="Issue" onClick={() => setShowReportIssuePopup(true)} />}
                {isReported && <img data-tooltip-id = "reported-question-tooltip" src={issueImageActive} alt="Issue" />}
              </div>
            }
          </div>

        </div>

        <div className={classes.section__main}>
          <p className={classes.section__main__question}>{language.value === 'english' ? questionsData[questionNumber-1].question : questionsData[questionNumber-1].question_rus}</p>
          
          <div id={questionsData[questionNumber-1].answers[0].answer_id} className={classes.section__main__answer} onClick={!isSubmited ? onAnswerSelect : undefined }>
            <p>{language.value === 'english' ? questionsData[questionNumber-1].answers[0].answer : questionsData[questionNumber-1].answers[0].answer_rus}</p>
          </div>
          
          <div id={questionsData[questionNumber-1].answers[1].answer_id} className={classes.section__main__answer} onClick={!isSubmited ? onAnswerSelect : undefined}>
            <p>{language.value === 'english' ? questionsData[questionNumber-1].answers[1].answer : questionsData[questionNumber-1].answers[1].answer_rus}</p>
          </div>
          
          <div id={questionsData[questionNumber-1].answers[2].answer_id} className={classes.section__main__answer} onClick={!isSubmited ? onAnswerSelect : undefined}>
            <p>{language.value === 'english' ? questionsData[questionNumber-1].answers[2].answer : questionsData[questionNumber-1].answers[2].answer_rus}</p>
          </div>
          
          <div id={questionsData[questionNumber-1].answers[3].answer_id} className={classes.section__main__answer} onClick={!isSubmited ? onAnswerSelect : undefined}>
            <p>{language.value === 'english' ? questionsData[questionNumber-1].answers[3].answer : questionsData[questionNumber-1].answers[3].answer_rus}</p>
          </div>

          {selectedAnswer && <button onClick={onSubmitAnswer}>Submit</button>}

          {nextBtn && <button onClick={onNextQuestion}>Next</button>}

        </div>

        {/* Popup for reporting an issue */}
        {showReportIssuePopup && <ReportIssuePopup
          handleClose={() => {setShowReportIssuePopup(false)}}
          handleSubmited={onIssueQuestion}
          title="Report an issue"
          content="Please describe the issue you have with this question. We will review it and make the necessary changes." 
        />}

        {/* Popup for not paid users */}
        {showPaymentReminder && <PopupReminder
          handleClose={() => {setShowPaymentReminder(false)}}
          title="Unlock Full Access!"
          image={loginImage}
          navigateTo="/exam-checkout"
          navigateToBtnText="Unlock"
          content="To continue your exam and unlock all features, please complete the payment process." />}
        
        {/* Tooltips */}
        <ReactTooltip
          id = "save-question-tooltip"
          place = "bottom"
          content = "Save question"
        />

        <ReactTooltip
          id = "delete-saved-question-tooltip"
          place = "bottom"
          content = "Remove from saved questions"
        />

        <ReactTooltip
          id = "report-question-tooltip"
          place = "bottom"
          content = "Report issue with the question"
        />

        <ReactTooltip
          id = "reported-question-tooltip"
          place = "bottom"
          variant = 'warning'
          content = "Question already reported"
        />
    </div>
  )
}

export default ExamSection